const _0 = "0"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "1rem"
const _4 = "1.5rem"
const _5 = "3rem"
const _6 = "4rem"
const _7 = "5rem"
const _8 = "6rem"
const _9 = "7rem"
const _10 = "10rem"
const _auto = "auto"
const _px = "1px"
const _full = "100%"
const _min = "min-content"
const _max = "max-content"
const _fit = "fit-content"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "auto": _auto, "px": _px, "full": _full, "min": _min, "max": _max, "fit": _fit, "0.5": "0.125rem", "2.5": "0.75rem", "1/2": "50%", "1/3": "33.333333%", "2/3": "66.666667%", "1/4": "25%", "2/4": "50%", "3/4": "75%", "1/5": "20%", "2/5": "40%", "3/5": "60%", "4/5": "80%", "1/6": "16.666667%", "2/6": "33.333333%", "3/6": "50%", "4/6": "66.666667%", "5/6": "83.333333%", "1/12": "8.333333%", "2/12": "16.666667%", "3/12": "25%", "4/12": "33.333333%", "5/12": "41.666667%", "6/12": "50%", "7/12": "58.333333%", "8/12": "66.666667%", "9/12": "75%", "10/12": "83.333333%", "11/12": "91.666667%",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _auto, _px, _full, _min, _max, _fit }