const _white = "#fff"
const _black = "#000"
const _transparent = "transparent"
const _sapphire = "#292c9f"
const _green = "#5cb54f"
const _yellow = "#e6b800"
const _orange = "#ff8200"
const _red = "#ff0000"
const _auto = "auto"
const config = { "white": _white, "black": _black, "transparent": _transparent, "sapphire": _sapphire, "green": _green, "yellow": _yellow, "orange": _orange, "red": _red, "auto": _auto, "dark-blue": "#14153f", "dark-blue-grey-two": "#0d1033", "dark-blue-grey": "#101032", "level-best-practice": "#63c954", "level-high": "#ff3736", "level-medium": "#ff962a", "level-standard": "#f3f5f7", "light-royal-blue": "#4353ff", "pale-grey": "#e7e7eb", "pale-lilac-two": "#d9dcff", "pale-lilac": "#f5f6ff", "pale-blue": "#e0f7ff", "pale-blue2": "#e3e3e5", "topic-antibiotics": "#a471fd", "topic-biodiversity": "#ffb12e", "topic-climate-change": "#ff7360", "topic-emerging-markets": "#78dbaf", "topic-nutrition": "#fd71a0", "ui-grey": "#ededed", "ui-grey2": "#d8d8d8", "ui-grey3": "#a1a0a0", "ui-grey4": "#f3f5f7", "fairr-light-blurple": "#ecedff",  }
export { config as default, _white, _black, _transparent, _sapphire, _green, _yellow, _orange, _red, _auto }