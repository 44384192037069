const _0 = "0"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "1rem"
const _4 = "1.5rem"
const _5 = "3rem"
const _6 = "4rem"
const _7 = "5rem"
const _8 = "6rem"
const _9 = "7rem"
const _10 = "10rem"
const _px = "1px"
const _none = "none"
const _xs = "20rem"
const _sm = "24rem"
const _md = "28rem"
const _lg = "32rem"
const _xl = "36rem"
const _2xl = "42rem"
const _3xl = "48rem"
const _4xl = "56rem"
const _5xl = "64rem"
const _6xl = "72rem"
const _7xl = "80rem"
const _full = "100%"
const _min = "min-content"
const _max = "max-content"
const _fit = "fit-content"
const _prose = "65ch"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "px": _px, "none": _none, "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl, "full": _full, "min": _min, "max": _max, "fit": _fit, "prose": _prose, "0.5": "0.125rem", "2.5": "0.75rem", "screen-sm": "640px", "screen-md": "768px", "screen-lg": "1024px", "screen-xl": "1280px", "screen-2xl": "1440px",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _px, _none, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl, _full, _min, _max, _fit, _prose }