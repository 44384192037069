const _0 = "0"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "1rem"
const _4 = "1.5rem"
const _5 = "3rem"
const _6 = "4rem"
const _7 = "5rem"
const _8 = "6rem"
const _9 = "7rem"
const _10 = "10rem"
const _px = "1px"
const _none = "none"
const _full = "100%"
const _screen = "100vh"
const _svh = "100svh"
const _lvh = "100lvh"
const _dvh = "100dvh"
const _min = "min-content"
const _max = "max-content"
const _fit = "fit-content"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "px": _px, "none": _none, "full": _full, "screen": _screen, "svh": _svh, "lvh": _lvh, "dvh": _dvh, "min": _min, "max": _max, "fit": _fit, "0.5": "0.125rem", "2.5": "0.75rem",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _px, _none, _full, _screen, _svh, _lvh, _dvh, _min, _max, _fit }