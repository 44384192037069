const _0 = "0"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "1rem"
const _4 = "1.5rem"
const _5 = "3rem"
const _6 = "4rem"
const _7 = "5rem"
const _8 = "6rem"
const _9 = "7rem"
const _10 = "10rem"
const _auto = "auto"
const _px = "1px"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "auto": _auto, "px": _px, "0.5": "0.125rem", "2.5": "0.75rem",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _auto, _px }